// 获取管理组
const getManageGroupTreeUrl = `/gateway/hc-space/space/getManageGroupTree`;
//删除角色
const deleteManageGroupUrl = `/gateway/hc-space/space/deleteManageGroup`;
//管理组类型
const getManageGroupTypeListUrl = `/gateway/hc-space/space/getManageGroupTypeList`;
//管理组管辖范围
const getManageGroupIncludeSpaceListUrl = `/gateway/hc-space/space/getManageGroupIncludeSpaceList`;
//获取管理组详情
const getManageGroupDetailUrl = `/gateway/hc-space/space/getManageGroupDetail`;
//新增管理组
const addManageGroupUrl = `/gateway/hc-space/space/addManageGroup`;
//编辑管理组
const modifyManageGroupUrl = `/gateway/hc-space/space/modifyManageGroup`;

//租户列表
const tenantListUrl = `/gateway/blade-system/tenant/select`;
// 获取角色列表
const roleListUrl = `/gateway/blade-system/role/list`;

//角色详情
const roleDetailUrl = `/gateway/blade-system/role/detail`;
//新增角色
const addRoleUrl = `/gateway/blade-system/role/submit`;
//删除角色
const deleteRoleUrl = `/gateway/blade-system/role/remove`;
//菜单列表
const menuListUrl = `/gateway/blade-system/menu/tree`;
//角色权限
const setMenuUrl = `/gateway/blade-system/role/grant`;
// 角色权限详情
const menuDetailUrl = `/gateway/blade-system/role/menu/list`;
// 模块权限详情
const getModuleDetail = `/gateway/blade-system/guide/list`;
// 模块权限编辑
const editModuleUrl = `/gateway/blade-system/guide/submit`;

export {
  getManageGroupTreeUrl,
  deleteManageGroupUrl,
  getManageGroupTypeListUrl,
  getManageGroupIncludeSpaceListUrl,
  getManageGroupDetailUrl,
  addManageGroupUrl,
  modifyManageGroupUrl,
  roleListUrl,
  tenantListUrl,
  roleDetailUrl,
  addRoleUrl,
  deleteRoleUrl,
  menuListUrl,
  setMenuUrl,
  menuDetailUrl,
  getModuleDetail,
  editModuleUrl,
};
