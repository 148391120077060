import { mapHelper } from "@/utils/common";

// 按钮列表
const btnOps = [
  // 新增管理组、编辑管理组、删除管理组、新增角色
  {
    text: "新增管理组",
    title: "新增管理组",
    code: 1,
    value: "addBtn",
  },
  {
    text: "编辑管理组",
    title: "编辑管理组",
    code: 2,
    value: "editBtn",
  },
  {
    text: "删除管理组",
    title: "删除管理组",
    code: 3,
    value: "deteleBtn",
  },
  {
    text: "新增角色",
    title: "新增角色",
    code: 4,
    value: "addRoleBtn",
  },
];

// 按钮角色
const btnRoleOps = [
  {
    text: "新增角色",
    code: 1,
    value: "addMemberBtn",
  },
  {
    text: "编辑角色",
    title: "编辑角色",
    code: 2,
    value: "editBtn",
  },
  {
    text: "删除角色",
    title: "删除角色",
    code: 3,
    value: "deteleBtn",
  },
  {
    text: "菜单权限",
    title: "菜单权限",
    code: 4,
    value: "powerBtn",
  },
  {
    text: "模块权限",
    title: "模块权限",
    code: 5,
    value: "powerBtn",
  },
];

// 树类型列表
const treeType = [
  {
    text: "平台职能部门",
    permission: "platformBtn",
    value: 1,
  },
  {
    text: "物业管理公司",
    permission: "companyBtn",
    value: 2,
  },
  {
    text: "社区治理单位",
    permission: "communityBtn",
    value: 3,
  },
];
const { map: treeTypeMap, setOps: treeTypeOps } = mapHelper.setMap(treeType);

// 账号状态
const permission = [
  {
    text: "后台",
    value: 0,
  },
  {
    text: "物管端",
    value: 1,
  },
  {
    text: "业主端",
    value: 2,
  },
];
const { map: permissionMap, setOps: permissionOps } =
  mapHelper.setMap(permission);

// 角色
const roleType = [
  {
    label: "租户",
    value: "tenant",
  },
  {
    label: "管理员",
    value: "administrator",
  },
  {
    label: "普通用户",
    value: "user",
  },
];
const { map: roleTypeMap, setOps: roleTypeOps } = mapHelper.setMap(roleType);

// 角色
const roleTypeList = [
  {
    label: "管理员",
    value: "administrator",
  },
  {
    label: "普通用户",
    value: "user",
  },
];
// modular 选择模块
const modularObj = [
  {
    label: "管理后台",
    value: 0,
  },
  {
    label: "驾驶舱",
    value: 1,
  },
  {
    label: "物联网平台",
    value: 2,
  },
  {
    label: "三维资产引擎",
    value: 3,
  },
  {
    label: "数据分析平台",
    value: 4,
  },
  {
    label: "未来社区管理",
    value: 6,
  },
];
const { map: modularObjMap, setOps: modularObjOps } =
  mapHelper.setMap(modularObj);
//  选择模块
const moduleList = [
  {
    label: "管理后台",
    value: 0,
  },
  {
    label: "驾驶舱",
    value: 1,
  },
  {
    label: "物联网平台",
    value: 2,
  },
  {
    label: "三维资产引擎",
    value: 3,
  },
  {
    label: "数据分析平台",
    value: 4,
  },
  {
    label: "应用能力中心",
    value: 5,
  },
  {
    label: "未来社区管理",
    value: 6,
  },
];
const { map: moduleListMap, setOps: moduleListOps } =
  mapHelper.setMap(moduleList);

export {
  roleTypeList,
  btnOps,
  btnRoleOps,
  treeTypeOps,
  treeTypeMap,
  permissionOps,
  permissionMap,
  roleType,
  roleTypeMap,
  roleTypeOps,
  modularObj,
  modularObjMap,
  modularObjOps,
  moduleListMap,
  moduleListOps,
};
